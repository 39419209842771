import { graphql } from '../generated-gql';

export const queryAdvertByKioskAndCategories = graphql(`
  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {
    advertByKioskAndCategories(input: $input) {
      id
      kioskId
      categoryId
      isActive
      imageUrl
    }
  }
`);
