import { graphql } from '../generated-gql';

export const queryAdvertsByKioskAndCategories = graphql(`
  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {
    advertsByKioskAndCategories(input: $input) {
      id
      kioskId
      categoryId
      isActive
    }
  }
`);
