import { graphql } from '../generated-gql/gql';
import { type CardQuery } from '../generated-gql/graphql';

export const queryCard = graphql(`
  query Card($cardId: ID!) {
    card(id: $cardId) {
      id
      cardName
      isActive
      sku
      rating
      ordering
      spanish
      designer {
        id
        name
      }
      images {
        id
        mainImageDescription
        previewMainPhotoUrl
        previewInteriorPhotoUrl
      }
      categories {
        id
      }
    }
  }
`);

export type NonNullableCard = NonNullable<CardQuery['card']>;
