import { graphql } from '../generated-gql';

export const queryCoupons = graphql(`
  query Coupons($input: CouponsInput) {
    coupons(input: $input) {
      id
      productId
      isActive
      expirationDate
      couponAmount
      maxRedemptions
      redemptions
      minimumAmount
    }
  }
`);
