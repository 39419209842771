import { graphql } from '../generated-gql';

export const queryAdverts = graphql(`
  query Adverts($input: AdvertsInput!) {
    adverts(input: $input) {
      id
      kioskId
      categoryId
      isActive
    }
  }
`);
