import { graphql } from '../generated-gql';

export const mutationAdminAdvertCreate = graphql(`
  mutation AdvertCreate($input: AdvertCreateInput!) {
    advertCreate(input: $input) {
      id
      imageUrl
    }
  }
`);
