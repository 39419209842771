import { graphql } from '../generated-gql';

export const queryAdvert = graphql(`
  query Advert($advertId: ID!) {
    advert(id: $advertId) {
      id
      kioskId
      categoryId
      isActive
      imageUrl
    }
  }
`);
