/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n": types.AdvertCreateDocument,
    "\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n": types.AdvertDeleteDocument,
    "\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n": types.AdvertUpdateDocument,
    "\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n": types.CardCreateDocument,
    "\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n": types.CardDeleteDocument,
    "\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n": types.CardImagesUploadUrlDocument,
    "\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n": types.CardUpdateDocument,
    "\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n": types.AdminCardsDocument,
    "\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n": types.AdminCardsByCategoriesDocument,
    "\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n": types.CategoryCreateDocument,
    "\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n": types.CategoryDeleteDocument,
    "\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n": types.CategoryUpdateDocument,
    "\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n": types.CouponCreateDocument,
    "\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n": types.CouponDeleteDocument,
    "\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n": types.CouponUpdateDocument,
    "\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n": types.AdminCreateDocument,
    "\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n": types.KioskCreateDocument,
    "\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n": types.KioskUpdateDocument,
    "\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n": types.AdminLoginDocument,
    "\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n": types.AdvertDocument,
    "\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n": types.AdvertByKioskAndCategoriesDocument,
    "\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n": types.AdvertsDocument,
    "\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n": types.AdvertsByKioskAndCategoriesDocument,
    "\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n": types.CategoriesDocument,
    "\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n": types.CategoriesWithRatingFilterDocument,
    "\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n": types.AdminCategoriesDocument,
    "\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n": types.AddonCategoriesDocument,
    "\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n": types.CardDocument,
    "\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n": types.CardsByCategoriesDocument,
    "\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n": types.CategoryDocument,
    "\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n    }\n  }\n": types.CouponDocument,
    "\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n": types.CouponRedemptionsIncrementDocument,
    "\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n    }\n  }\n": types.CouponsDocument,
    "\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n": types.OrderCreateDocument,
    "\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n": types.OrderReceiptCreateDocument,
    "\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n": types.PersonalizedCardCreateDocument,
    "\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n": types.PersonalizedCardsCreateDocument,
    "\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n": types.OrderForPickupDocument,
    "\n  query GiftCards {\n    giftCards {\n      denominationType\n      id\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n    }\n  }\n": types.GiftCardsDocument,
    "\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      cardMaxRating\n      productId\n      disableGiftCards\n    }\n  }\n": types.KioskDocument,
    "\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      printIncludeLogo\n      disableGiftCards\n    }\n  }\n": types.KiosksDocument,
    "\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n": types.MobileOrderPickupCodeDocument,
    "\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n": types.AdminOrderDetailsDocument,
    "\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n": types.OrderDetailsDocument,
    "\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n": types.OrderMobilePickupDocument,
    "\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n": types.OrdersDocument,
    "\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n": types.CardPrintQualityImageDocument,
    "\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n": types.QueryShippingOrdersDocument,
    "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n": types.OrderUpdateDocument,
    "\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n": types.UserSubscribeNewsletterDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n"): (typeof documents)["\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n"): (typeof documents)["\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n"): (typeof documents)["\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n"): (typeof documents)["\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n"): (typeof documents)["\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n"): (typeof documents)["\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"): (typeof documents)["\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"): (typeof documents)["\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n"): (typeof documents)["\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n    }\n  }\n"): (typeof documents)["\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n    }\n  }\n"): (typeof documents)["\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n"): (typeof documents)["\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n"): (typeof documents)["\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GiftCards {\n    giftCards {\n      denominationType\n      id\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n    }\n  }\n"): (typeof documents)["\n  query GiftCards {\n    giftCards {\n      denominationType\n      id\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      cardMaxRating\n      productId\n      disableGiftCards\n    }\n  }\n"): (typeof documents)["\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      cardMaxRating\n      productId\n      disableGiftCards\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      printIncludeLogo\n      disableGiftCards\n    }\n  }\n"): (typeof documents)["\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      printIncludeLogo\n      disableGiftCards\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"): (typeof documents)["\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n"): (typeof documents)["\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"): (typeof documents)["\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n"): (typeof documents)["\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;